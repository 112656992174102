import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { clarity } from 'clarity-js';
import { useCallback } from 'react';

export function useTrackEvents() {
  const appInsights = useAppInsightsContext();

  const trackClickToSearch = useCallback(
    (keywords) => {
      appInsights?.trackEvent({
        name: 'onClickToSearch',
        properties: {
          keywords,
        },
      });
      clarity.event('onClickToSearch', keywords);
    },
    [appInsights],
  );

  const trackPressEnterToSearch = useCallback(
    (keywords) => {
      appInsights?.trackEvent({
        name: 'onPressEnterToSearch',
        properties: {
          keywords,
        },
      });
      clarity.event('onPressEnterToSearch', keywords);
    },
    [appInsights],
  );

  const trackDocumentPreview = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onPreviewDocument',
        properties: {
          documentNo,
        },
      });
      clarity.event('onPreviewDocument', documentNo);
    },
    [appInsights],
  );

  const trackPreviewDocumentButtonClick = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onPreviewDocumentButtonClick',
        properties: {
          documentNo,
        },
      });
      clarity.event('onPreviewDocumentButtonClick', documentNo);
    },
    [appInsights],
  );

  const trackDownloadButtonClick = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onDownloadButtonClick',
        properties: {
          documentNo,
        },
      });
      clarity.event('onDownloadButtonClick', documentNo);
    },
    [appInsights],
  );

  const trackDocumentLinkCopy = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onDocumentLinkCopy',
        properties: {
          documentNo,
        },
      });
      clarity.event('onDocumentLinkCopy', documentNo);
    },
    [appInsights],
  );

  const trackProjectSelect = useCallback(
    (projects) => {
      appInsights?.trackEvent({
        name: 'onDocumentLinkCopy',
        properties: {
          projects,
        },
      });
      clarity.event('onDocumentLinkCopy', projects);
    },
    [appInsights],
  );

  const trackViewChange = useCallback((view) => {
    clarity.event(
      view === 'table' ? 'onTableViewChange' : 'onThumbnailViewChange',
      view,
    );
  }, []);

  return {
    trackClickToSearch,
    trackDocumentLinkCopy,
    trackDocumentPreview,
    trackDownloadButtonClick,
    trackPressEnterToSearch,
    trackPreviewDocumentButtonClick,
    trackProjectSelect,
    trackViewChange,
  };
}
