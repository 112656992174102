import { useQuery } from '@tanstack/react-query';

import { fetchJsonApi } from '@/helpers/api';

export type NativeFile = {
  mime_type: string;
  title: string;
  url: string;
};

export default function useNativeFileDownloadLinksQuery({
  documentId,
  enabled,
}: {
  documentId: string;
  enabled?: boolean;
}) {
  return useQuery({
    enabled,
    queryFn: async () => {
      const documentRequestResponse = await fetchJsonApi(
        `/file/download/${documentId}/document-requests/new`,
      );
      const documentRequest = await documentRequestResponse.json();

      let documentRequestList;

      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };

      while (
        !documentRequestList ||
        documentRequestList.status !== 'completed'
      ) {
        const documentRequestListResponse = await fetchJsonApi(
          `/file/download/document-requests/${documentRequest.uuid}/result`,
        );
        documentRequestList = await documentRequestListResponse.json();

        await sleep(1000);
      }

      return documentRequestList.result as NativeFile[];
    },
    queryKey: ['documentRequest', documentId],
  });
}
