import { useQuery } from '@tanstack/react-query';

export default function useNativeFileAvailabilityQuery() {
  return useQuery({
    queryFn: async () => {
      try {
        await fetch('https://projdocs.modec.com/', {
          mode: 'no-cors',
        });

        return true;
      } catch {
        return false;
      }
    },
    queryKey: ['nativeFileAvailability'],
  });
}
