import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FileInfoV2 } from '@/services/SearchService';

import { FitView, ZoomConfig } from '../../constants';
import { trimSearchTermForPdf } from '../../helpers/utils';
import { FitHeightIcon, FitOriginIcon, FitWidthIcon } from '../icons';
import { NumericInputStandalone } from '../inputs';
import { SearchTerm } from '../pdf/pdf';
import DownloadButton from './DownloadButton';
import { HighlightedKeyword } from './HighlightedKeyword';
import { HoverableDetailTitleStandalone } from './HoverableDetailTitleStandalone';

interface DetailHeaderProps {
  disabled?: boolean;
  file: FileInfoV2;
  findKeywordIndex: Record<string, number>;
  fitTypeProp?: string;
  handleFitViewerCallback: (type: string) => void;
  handleNextHighlight: (keyword: string) => MouseEventHandler;
  handlePreviousHighlight: (keyword: string) => MouseEventHandler;
  onHide: () => void;
  onScalePercentageChange: (value: number) => void;
  pdfMatchedSearchTerms?: Record<string, number>;
  pdfOrderedMatchedSearchTerms?: SearchTerm[];
  scale: number | undefined;
  selectedKeywords: Record<string, boolean>;
  title: string;
  toggleKeyword: (keyword: string) => MouseEventHandler;
}

export function DetailHeaderStandalone(props: DetailHeaderProps) {
  const {
    file,
    findKeywordIndex,
    fitTypeProp,
    handleFitViewerCallback,
    handleNextHighlight,
    handlePreviousHighlight,
    onScalePercentageChange,
    pdfMatchedSearchTerms,
    pdfOrderedMatchedSearchTerms,
    scale,
    selectedKeywords,
    title,
    toggleKeyword,
  } = props;
  const [fitType, setFitType] = useState<string>();

  useEffect(() => {
    const defaultFitType = FitView.ORIGIN;
    setFitType(defaultFitType);
  }, [fitTypeProp]);

  const handleFitViewer = (type: string) => () => {
    setFitType(type);
    handleFitViewerCallback(type);
  };

  const formatZoomPercentage = useCallback((value: number) => `${value}%`, []);

  const renderSelectedKeywords = useMemo(() => {
    if (!pdfOrderedMatchedSearchTerms?.length) {
      return '';
    }

    return pdfOrderedMatchedSearchTerms?.map(
      (item: SearchTerm, index: number) => {
        const trimmedTerm = trimSearchTermForPdf(item.term);

        return (
          <HighlightedKeyword
            color={item.color}
            findKeywordIndex={findKeywordIndex}
            formattedKeyword={trimmedTerm}
            handleNextHighlight={handleNextHighlight}
            handlePreviousHighlight={handlePreviousHighlight}
            index={index}
            key={index}
            keyword={item.term}
            selectedKeywords={selectedKeywords}
            toggleKeyword={toggleKeyword}
            total={
              pdfMatchedSearchTerms ? pdfMatchedSearchTerms[trimmedTerm] : 0
            }
          />
        );
      },
    );
  }, [
    selectedKeywords,
    findKeywordIndex,
    pdfMatchedSearchTerms,
    handleNextHighlight,
  ]);

  return (
    <>
      <div
        className="flex w-full items-center"
        style={{
          height: '3.75rem',
        }}
      >
        <HoverableDetailTitleStandalone file={file} title={title} />
      </div>

      <div
        style={{
          alignItems: 'center',
          border: `1px solid #616161`,
          display: 'flex',
          height: '3.5rem',
        }}
      >
        <div
          className="selected-keywords flex items-center px-3"
          style={{
            height: '3.5rem',
            width: '80%',
          }}
        >
          {renderSelectedKeywords !== '' && (
            <div className="keyword">
              <div className="content">
                <div className="s-tags custom">{renderSelectedKeywords}</div>
              </div>
            </div>
          )}

          <div className="ms-auto flex">
            <DownloadButton
              documentId={file.uuid}
              documentNo={file.document_number}
            />
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            borderLeft: '1px solid rgb(97, 97, 97)',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '8%',
          }}
        >
          <div>
            <FitOriginIcon
              className={fitType === FitView.ORIGIN ? 'ic active' : 'ic'}
              onClick={handleFitViewer(FitView.ORIGIN)}
            />
          </div>
          <div className={'px-4'}>
            <FitWidthIcon
              className={fitType === FitView.WIDTH ? 'ic active' : 'ic'}
              onClick={handleFitViewer(FitView.WIDTH)}
            />
          </div>
          <div>
            <FitHeightIcon
              className={fitType === FitView.HEIGHT ? 'ic active' : 'ic'}
              onClick={handleFitViewer(FitView.HEIGHT)}
            />
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            borderLeft: '1px solid rgb(97, 97, 97)',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '12%',
          }}
        >
          <div className="numeric-input">
            <NumericInputStandalone
              defaultValue={ZoomConfig.DEFAULT}
              formatter={formatZoomPercentage}
              max={ZoomConfig.MAX}
              min={ZoomConfig.MIN}
              onChange={onScalePercentageChange}
              slideStep={ZoomConfig.SLIDE_STEP}
              step={ZoomConfig.STEP}
              value={scale}
            />
          </div>
        </div>
      </div>
    </>
  );
}
