import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, MenuItem, Popper, Select } from '@mui/material';
import { useAtom } from 'jotai';
import { useRef, useState } from 'react';

import { ReactComponent as HelpIcon } from '@/assets/icons/help.svg';

import { helpFormOpenAtom } from './atom';
import HelpForm from './Form';

export default function Help() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const arrowRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useAtom(helpFormOpenAtom);
  const id = open ? 'support-form-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const [selectedOption, setSelectedOption] = useState<'Document' | 'Other'>(
    'Document',
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <HelpIcon />
      </IconButton>

      <Popper
        anchorEl={anchorEl}
        className="z-[1]"
        id={id}
        open={open}
        placement="bottom-end"
        popperOptions={{
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: arrowRef.current,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 5],
              },
            },
          ],
        }}
      >
        <div
          className="absolute -top-[8px] right-[15px] h-0 w-0 border-b-[8px] border-l-[6px] border-r-[6px] border-transparent border-b-[#434343]"
          ref={arrowRef}
        />

        <div className="w-96 max-w-[100vw] !rounded-lg bg-[#434343] p-6 shadow-2xl">
          <label className="!mb-3">Please let us know how we can help</label>

          <Select
            className="h-10 w-full rounded-lg bg-white"
            onChange={(event) => {
              setSelectedOption(event.target.value as 'Document' | 'Other');
            }}
            placeholder="Select from list"
            value={selectedOption}
          >
            <MenuItem value="Document">
              I cannot find desired document(s)
            </MenuItem>
            <MenuItem value="Other">Others</MenuItem>
          </Select>

          {selectedOption && <HelpForm selectedOption={selectedOption} />}
        </div>
      </Popper>
    </>
  );
}
