import { Modal, ModalProps } from '@mui/material';
import PDF from 'pdfjs';
import { useEffect, useState } from 'react';

export default function DocumentModal({
  onClose,
  open,
}: {
  onClose: ModalProps['onClose'];
  open: boolean;
}) {
  const [pdf, setPdf] = useState(() => new PDF.Document());

  useEffect(() => {}, []);

  return (
    <Modal onClose={onClose} open={open}>
      <div></div>
    </Modal>
  );
}
