import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Box,
  Chip,
  ClickAwayListener,
  IconButton,
  Input,
  InputAdornment,
  Popper,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  removeSearchLogAtom,
  searchLogQueryAtom,
  tagsAtom,
} from '@/atoms/searchLog';

type SearchInputProps = {
  onSearch: (tags: string[]) => void;
};

export default function SearchInput({ onSearch }: SearchInputProps) {
  const anchorEl = useRef<HTMLDivElement>(null);
  const [logOpen, setLogOpen] = useState(false);
  const [selectedTokenIndex, setSelectedTokenIndex] = useState<null | number>(
    null,
  );
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [tags, setTags] = useAtom(tagsAtom);
  const { data } = useAtomValue(searchLogQueryAtom);
  const { mutate: removeSearchLog } = useAtomValue(removeSearchLogAtom);
  const [searchInputValue, setSearchInputValue] = useAtom(searchInputValueAtom);
  const setPreselectedProjects = useSetAtom(preselectedProjectsAtom);
  const setPreselectedView = useSetAtom(preselectedViewAtom);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const currentTags = searchParams.getAll('q');
    setTags(currentTags);
  }, [searchParams, setTags]);

  useEffect(() => {
    if (pathname !== '/') {
      setTimeout(() => {
        setNoticeOpen(isBefore(new Date(), new Date('2024-07-30')));
      }, 1000);
    }
  }, [pathname]);

  const [selectedTokenRef, setSelectedTokenRef] =
    useState<HTMLSpanElement | null>(null);

  useEffect(() => {
    // selectedTokenIndex can be 0
    if (selectedTokenRef && selectedTokenIndex !== null) {
      selectedTokenRef?.focus();
    }
  }, [selectedTokenIndex, selectedTokenRef]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setLogOpen(false);
        }}
      >
        <Box className="w-full">
          <Input
            className="h-14 rounded border border-[#FFF9] !p-4 text-white placeholder:text-white focus:border-[#3F5BFF] [&.Mui-focused]:after:content-none"
            endAdornment={
              tags.length > 0 && (
                <IconButton
                  className="!text-[#828282]"
                  onClick={() => {
                    setTags([]);
                    setPreselectedProjects([]);
                    setPreselectedView('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            fullWidth
            onChange={(event) => {
              setSearchInputValue(event.target.value);
            }}
            // onClick={() => {
            //   if (tags.length === 0) {
            //     setLogOpen(true);
            //   }
            // }}
            onKeyDown={(event) => {
              setLogOpen(false);

              if (event.key === 'Enter') {
                if (searchInputValue) {
                  if (searchInputValue.length <= 1) {
                    enqueueSnackbar('A minimum of two characters required', {
                      variant: 'warning',
                    });

                    return;
                  }

                  if (tags.includes(searchInputValue)) {
                    enqueueSnackbar(t('keyword_exist'), {
                      variant: 'warning',
                    });

                    return;
                  }

                  setTags((prevTags) => [...prevTags, searchInputValue]);
                  setSearchInputValue('');

                  return;
                }

                // createSearchLog({
                //   displayType: searchParams.get('view') ?? '',
                //   projectCodes: searchParams.getAll('project_codes') || [],
                //   tags,
                // });
                onSearch(tags);
              } else if (event.key === 'Escape') {
                setLogOpen(false);
              } else if (event.key === 'Backspace') {
                if (!searchInputValue) {
                  setTags((prevTags) => prevTags.slice(0, -1));
                }
              }
            }}
            placeholder={
              tags.length
                ? 'Add more keywords to refine your search'
                : 'Press "Enter" to search with multiple keywords e.g ‘equipment list’ ‘SI-3160’'
            }
            ref={anchorEl}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className="!mr-2 text-[#828282]" />

                {tags.map((tag, idx) =>
                  selectedTokenIndex === idx ? (
                    <span
                      className={twMerge(
                        'flex h-8 items-center rounded-2xl px-3 text-white before:!content-none after:!content-none focus-visible:outline-none [&:not(:last-child)]:mr-2',
                        `!bg-[${tagColors[idx].replaceAll(' ', '')}]`,
                      )}
                      contentEditable
                      defaultValue={tag}
                      onBlur={(event) => {
                        setTags((prevTags) => {
                          prevTags[selectedTokenIndex] =
                            event.currentTarget.innerText;
                          return [...prevTags];
                        });
                        setSelectedTokenIndex(null);
                      }}
                      onFocus={(e) => {
                        const selection = getSelection();
                        selection?.selectAllChildren(e.currentTarget);
                        selection?.collapseToEnd();
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === 'Escape') {
                          setTags((prevTags) => {
                            prevTags[selectedTokenIndex] =
                              event.currentTarget.innerText;
                            return [...prevTags];
                          });
                          setSelectedTokenIndex(null);
                        }
                      }}
                      ref={setSelectedTokenRef}
                    >
                      {tag}
                    </span>
                  ) : (
                    <Chip
                      classes={{
                        deleteIcon: 'text-white',
                        root: `!bg-[${tagColors[idx].replaceAll(' ', '')}] !text-base text-white [&:not(:last-child)]:mr-2`,
                      }}
                      key={tag}
                      label={tag}
                      onClick={() => setSelectedTokenIndex(idx)}
                      onDelete={() =>
                        setTags((prevTags) => prevTags.filter((t) => t !== tag))
                      }
                    />
                  ),
                )}
              </InputAdornment>
            }
            value={searchInputValue}
          />

          <Popper
            anchorEl={anchorEl.current}
            className="z-[1]"
            open={logOpen && data.length > 0}
          >
            <Box
              className="rounded-lg bg-[#111] py-[6px] shadow-md"
              sx={{
                width: anchorEl.current?.clientWidth + 'px',
              }}
            >
              {data?.map((log) => {
                return (
                  <Box
                    className="flex h-10 items-center !px-3 !py-1 hover:bg-[#FFFFFF14] hover:text-white hover:no-underline"
                    key={log.id}
                    onClick={() => {
                      setTags(log.terms);
                      setPreselectedProjects(log.project_codes);
                      setPreselectedView(log.view_type);
                      setLogOpen(false);
                    }}
                  >
                    <UpdateIcon className="mr-3" />

                    {log.terms.map((term) => (
                      <Chip
                        className="mr-1 !h-6 !bg-[#333] px-2 !text-sm !text-[#DDD]"
                        key={term}
                        label={term}
                      />
                    ))}

                    <IconButton
                      className="!ms-auto !text-[#D9D9D9]"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSearchLog(log.id);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>

      <Popper
        anchorEl={anchorEl.current}
        open={noticeOpen}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={() => {
            setNoticeOpen(false);
          }}
        >
          <div className="pt-3">
            <div className="absolute left-[6px] top-2 -z-[1] border-x-[9px] border-b-[9px] border-[#333] border-x-transparent" />
            <div className="w-[184px] rounded-md bg-[#333] px-[6px] py-1 text-center text-sm font-medium text-white">
              Click on a keyword to make edits
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export const searchInputValueAtom = atom('');
export const preselectedProjectsAtom = atom<string[]>([]);
export const preselectedViewAtom = atom('');

export const tagColors = [
  'rgba(255, 71, 71, 0.75)',
  'rgba(255, 191, 71, 0.75)',
  'rgba(246, 81, 166, 0.75)',
  'rgba(71, 255, 71, 0.75)',
  'rgba(71, 71, 255, 0.75)',
  'rgba(178, 71, 255, 0.75)',
  'rgba(255, 71, 255, 0.75)',
  'rgba(150, 170, 197, 0.75)',
  'rgba(71, 234, 255, 0.75)',
  'rgba(183, 143, 154, 0.75)',
  'rgba(185, 246, 81, 0.75)',
  'rgba(255, 224, 71, 0.75)',
  'rgba(76, 76, 76, 0.75)',
  'rgba(245, 0, 0, 0.75)',
  'rgba(163, 106, 0, 0.75)',
  'rgba(245, 204, 0, 0.75)',
  'rgba(0, 245, 0, 0.75)',
  'rgba(0, 0, 245, 0.75)',
  'rgba(143, 0, 245, 0.75)',
  'rgba(245, 0, 245, 0.75)',
  'rgba(97, 128, 168, 0.75)',
  'rgba(0, 216, 245, 0.75)',
  'rgba(149, 95, 109, 0.75)',
  'rgba(152, 233, 12, 0.75)',
  'rgba(233, 12, 126, 0.75)',
  'rgba(163, 0, 0, 0.75)',
  'rgba(163, 106, 0, 0.75)',
  'rgba(163, 136, 0, 0.75)',
  'rgba(0, 163, 0, 0.75)',
  'rgba(0, 0, 163, 0.75)',
  'rgba(95, 0, 163, 0.75)',
  'rgba(163, 0, 163, 0.75)',
  'rgba(65, 88, 118, 0.75)',
  'rgba(0, 144, 163, 0.75)',
  'rgba(100, 64, 73, 0.75)',
  'rgba(101, 155, 8, 0.75)',
  'rgba(155, 8, 84, 0.75)',
  'rgba(82, 0, 0, 0.75)',
  'rgba(82, 53, 0, 0.75)',
  'rgba(82, 69, 0, 0.75)',
  'rgba(0, 82, 0, 0.75)',
  'rgba(0, 0, 82, 0.75)',
  'rgba(48, 0, 82, 0.75)',
  'rgba(82, 0, 82, 0.75)',
  'rgba(36, 49, 66, 0.75)',
  'rgba(0, 72, 82, 0.75)',
  'rgba(50, 32, 37, 0.75)',
  'rgba(50, 77, 4, 0.75)',
  'rgba(77, 4, 42, 0.75)',
  'rgba(204, 204, 204, 0.75)',
];
