import { useMsal } from '@azure/msal-react';
import { ReactElement, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { msal } from '../../helpers/msal';

export function LoginButton(): ReactElement {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [isSilentProcessing, setIsSilentProcessing] = useState(false);

  useEffect(() => {
    const userAccount = msal.getAccount();
    setIsSilentProcessing(!!userAccount);
  }, []);

  const onLogin = () => {
    instance.loginRedirect();
  };

  return (
    <Button onClick={onLogin} size="sm" type="button">
      {isSilentProcessing ? (
        <Spinner animation="border" />
      ) : (
        t('landing_button_sign_in')
      )}
    </Button>
  );
}
