import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentThumb } from '@/components/document/DocumentThumb';
import { SearchTerm } from '@/components/pdf/pdf';
import { clarityService, DOWNLOAD_OPTION, fileService } from '@/services';

import { ApiDefaultValue, colors, VIEW_OPTION } from '../../constants';
import { formatDateString } from '../../helpers/utils';
import { FileInfoV2 } from '../../services/SearchService';
import { DataGrid, ISelectedDocument } from '../DataGrid';
import DocumentPopper from '../document/DocumentPopper';

export default function SearchResultView({
  data,
  onViewPdf,
  showPdfModal,
  tags,
  view,
}) {
  const {
    i18n: { language },
  } = useTranslation();

  const revisionDateHeaderFilter = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    // Find unique years from dataSource
    const uniqueDates = new Map<string, Record<string, Date | string>>();

    data.forEach((file: FileInfoV2) => {
      const revisionDate = file.revision_date
        ? new Date(file.revision_date)
        : new Date();
      const yyyyFormatDate = formatDateString(
        file.revision_date,
        language,
        `yyyy`,
      );
      uniqueDates.set(yyyyFormatDate, {
        text: yyyyFormatDate,
        value: revisionDate,
      });
    });

    // convert map to array
    const uniqueYears = Array.from(uniqueDates, ([key, value]) => ({
      text: key,
      value: value,
    }));

    // sorting by years
    uniqueYears.sort((a, b) => Number(b.text) - Number(a.text));

    return uniqueYears;
  }, [data, language]);

  const coloredTerms: SearchTerm[] = useMemo(
    () =>
      tags.map((value: string, index: number) => {
        return { color: colors[index].rgba, term: value };
      }),
    [tags],
  );

  const columns = useMemo(
    () => [
      { allowSearch: true, name: 'project_code', title: 'Project Code' },
      { allowSearch: true, name: 'project_name', title: 'Project Name' },
      {
        allowSearch: true,
        cellRender: (cellInfo) => {
          return (
            <div
              style={{
                cursor: `pointer`,
                textDecoration: 'underline',
              }}
            >
              {cellInfo.data.document_number}
            </div>
          );
        },
        name: 'document_number',
        title: 'Document Number',
      },
      { allowSearch: true, name: 'title', title: 'Title', width: 400 },
      { allowSearch: true, name: 'revision', title: 'Revision' },
      { allowSearch: true, name: 'state', title: 'Status' },
      {
        allowSearch: true,
        customizeText: (cellInfo) => {
          return formatDateString(cellInfo.value, language);
        },
        dataType: 'date',
        headerFilter: {
          dataSource: revisionDateHeaderFilter,
        },
        name: 'revision_date',
        title: 'Revision Date',
      },
      { allowSearch: true, name: 'sdrl_code', title: 'SDRL Code' },
      { allowSearch: true, name: 'document_type', title: 'Document Type' },
      {
        allowSearch: true,
        name: 'type_of_document',
        title: 'Type of Document',
      },
      {
        allowSearch: true,
        name: 'discipline',
        title: 'Discipline',
      },
      {
        allowHeaderFiltering: false,
        allowSearch: false,
        allowSorting: false,
        cellRender: (cellInfo) => {
          return (
            <DocumentPopper
              file={cellInfo.data}
              viewFile={() => onViewPdf(cellInfo.data, coloredTerms)}
            />
          );
        },
        fixed: true,
        fixedPosition: 'right',
      },
    ],
    [coloredTerms, language, onViewPdf, revisionDateHeaderFilter],
  );

  switch (view) {
    case VIEW_OPTION.TABLE:
      return (
        <DataGrid
          columns={columns}
          data={data}
          onDocumentNumberClick={(cellClickData) => {
            if (
              cellClickData.column?.name === 'document_number' &&
              cellClickData.data
            ) {
              onViewPdf(cellClickData.data, coloredTerms);
            }
          }}
          onDownloadMultipleFiles={(
            selectedDocuments: ISelectedDocument[],
          ): void => {
            fileService.saveMultipleFiles(selectedDocuments);
            clarityService.trackingDownloadHistory(
              DOWNLOAD_OPTION.multiple,
              selectedDocuments.length,
            );
          }}
          onDownloadMultipleFilesAsZip={(
            selectedDocuments: ISelectedDocument[],
          ): void => {
            fileService.saveMultipleFilesAsZip(selectedDocuments);
            clarityService.trackingDownloadHistory(
              DOWNLOAD_OPTION.multiple_as_zip,
              selectedDocuments.length,
            );
          }}
          onRowDownloadClick={(file: FileInfoV2): void => {
            fileService.saveFile(file.uuid);
            clarityService.trackingDownloadHistory(
              DOWNLOAD_OPTION.single,
              1,
              file,
            );
          }}
          onRowPreviewClick={(file: FileInfoV2): void => {
            onViewPdf(file, coloredTerms);
          }}
        />
      );
    case VIEW_OPTION.THUMBNAIL:
      return data
        .slice(0, ApiDefaultValue.LIMIT_BT_THUMBNAIL_VIEW)
        .map((file: FileInfoV2) => (
          <DocumentThumb
            file={file}
            key={file.uuid}
            onViewPdf={onViewPdf}
            queries={tags}
            showPdfModal={showPdfModal}
            tags={tags}
            view={view}
          />
        ));
    default:
      return null;
  }
}
