import queryString from 'query-string';
import { PdfHighlight } from 'react-pdf-highlighter';

import { PageUrl, TypeView } from '../../constants';
import { FileInfoV2 } from '../../services/SearchService';
import { ThumbDetails } from '../commons/ThumbDetails';
import { ImagePreview } from '../pdf/ImagePreview';
import { SearchTerm } from '../pdf/pdf';

interface DocumentThumbProps {
  file: FileInfoV2;
  onViewPdf: (
    file: FileInfoV2,
    searchTerms: SearchTerm[],
    highlight?: PdfHighlight,
  ) => void;
  queries: string[];
  showPdfModal: boolean;
  tags: string[];
  view: TypeView;
}

export const DocumentThumb = (props: DocumentThumbProps) => {
  const { file } = props;
  const pathname: string = PageUrl.DETAIL;
  const document_number = file.document_number;
  const revision = file.revision;
  const uuid = file.uuid;
  const queryStringObj = queryString.parse(window.location.search);
  const detailPageUrl = `${
    window.location.origin
  }${pathname}?${queryString.stringify({
    ...queryStringObj,
    document_number,
    revision,
    uuid,
  })}`;

  return (
    <div className="item flex flex-col">
      <div className="content">
        <ImagePreview {...props} detailPageUrl={detailPageUrl} />
      </div>

      <ThumbDetails file={file} onViewPdf={props.onViewPdf} tags={props.tags} />
    </div>
  );
};
