import { useQuery } from '@tanstack/react-query';

import getDocumentDetail from './apis/get-document-detail';

export default function useDocumentDetailQuery({
  documentId,
  tags,
}: {
  documentId: string;
  tags: string[];
}) {
  return useQuery({
    enabled: documentId !== '',
    queryFn: async () => {
      return await getDocumentDetail({ documentId, tags });
    },
    queryKey: ['documentDetail', documentId, tags],
  });
}
