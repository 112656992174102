import '@fontsource/noto-sans';
import 'devextreme/dist/css/dx.dark.css';
import './assets/scss/main.scss';
import './index.css';
import './localization/i18n';

import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { clarity } from 'clarity-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import Smartlook from 'smartlook-client';

import { appInsights } from './analytics/application-insights';
import { msalInstance } from './helpers/msal';
import { router } from './router';
import { clarityService } from './services';

const queryClient = new QueryClient();

msalInstance
  .initialize()
  .then(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);

      if (accounts[0].name && accounts[0].username) {
        clarityService.init(accounts[0].name, accounts[0].username);
      }
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        msalInstance.setActiveAccount(account);

        appInsights.setAuthenticatedUserContext(account.username);
      }
    });

    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </MsalProvider>
      </React.StrictMode>,
    );
  })
  .catch(console.error);

if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights();

  clarity.start({
    projectId: process.env.REACT_APP_CLARITY_PROJECT_ID,
  });

  if (process.env.REACT_APP_SMARTLOOK_KEY)
    Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY);
}
