// import ContextMenu from 'devextreme-react/context-menu';
import { CellClickEvent } from 'devextreme/ui/data_grid';
import {
  Column,
  ColumnFixing,
  DataGrid as DevExtremeDataGrid,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import React from 'react';

import { FileInfoV2 } from '../../services/SearchService';

interface IColumn {
  allowSearch?: boolean;
  cellRender?: (cellInfo) => React.ReactNode;
  customizeText?: (cellInfo) => string;
  dataType?: string;
  headerFilter?: Record<string, unknown>;
  name?: string;
  project_name?: number;
  title?: string;
  width?: number;
}

export interface ISelectedDocument {
  document_number: string;
  uuid: string;
}

interface ICustomDataGridProps {
  children?: React.ReactNode;
  columns: IColumn[];
  data: FileInfoV2[];
  onDocumentNumberClick: (e: CellClickEvent) => void;
  onDownloadMultipleFiles: (multipleDownloadProps: ISelectedDocument[]) => void;
  onDownloadMultipleFilesAsZip: (
    multipleDownloadProps: ISelectedDocument[],
  ) => void;
  onRowDownloadClick: (file: FileInfoV2) => void;
  onRowPreviewClick: (file: FileInfoV2) => void;
}

interface ICustomDataGridStates {
  autoNavigateToFocusedRow: boolean;
  // loadPanelEnabled: boolean;
  checkboxesMode: string;
  currentFilter: any;
  focusedRowKey: null | string | undefined;
  selectedDocuments: ISelectedDocument[];
  selectedRowKeys: string[];
  showFilterRow: boolean;
  showHeaderFilter: boolean;
}

// TODO: to port to react hook
export class DataGrid extends React.Component<
  ICustomDataGridProps,
  ICustomDataGridStates
> {
  addMenuItems = (e: any): void => {
    if (e.target === 'content') {
      if (!e.items) e.items = [];

      // multiple selection
      if (this.state.selectedRowKeys.length > 1) {
        // Business: maximum 50 items per download (Quan Nguyen <quan.nguyen@modec.com>)
        const _selectedDocuments: ISelectedDocument[] =
          this.state.selectedDocuments.slice(0, 50);
        e.items.push(
          {
            onItemClick: () =>
              this.props.onDownloadMultipleFiles(_selectedDocuments),
            text: 'Download selected documents as pdf (up to 50 items)',
          },
          {
            onItemClick: () => {
              this.props.onDownloadMultipleFilesAsZip(_selectedDocuments);
            },
            text: 'Download selected documents as zip (up to 50 items)',
          },
        );
      } else {
        // single selection
        e.items.push(
          {
            onItemClick: () =>
              this.props.onRowDownloadClick(e.row.data as FileInfoV2),
            text: 'Download',
          },
          {
            onItemClick: () =>
              this.props.onRowPreviewClick(e.row.data as FileInfoV2),
            text: 'Preview',
          },
        );
      }
    }
  };
  applyFilterTypes: any[];
  clearFilter = (): void => {
    this.dataGridRef.current?.instance.clearFilter();
  };
  columns: IColumn[];
  // contextMenuItems?: IContextMenu[];
  dataGridRef: React.RefObject<DevExtremeDataGrid>;

  dataSource?: any[] | null;

  onCurrentFilterChanged = (e: any): void => {
    this.setState({
      currentFilter: e.value,
    });
  };

  onFocusedRowChanged = (e: any): void => {
    this.setState({
      focusedRowKey: e.component.option('focusedRowKey'),
    });
  };

  onFocusedRowChanging = (e: any): void => {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;

    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option('focusedRowIndex', 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option('focusedRowIndex', rowsCount - 1);
        });
      }
    }
  };

  // onContextMenuItemClick = (e: any) => {
  // 	if (!e.itemData.items) {
  // 		console.log(`The "${e.itemData.text}" item was clicked`);
  // 	}
  // };

  onSelectionChanged = (e: any): void => {
    const selectedRowsData = e.selectedRowsData.map((file: FileInfoV2) => {
      return {
        document_number: file.document_number,
        uuid: file.uuid,
      };
    });

    this.setState({
      selectedDocuments: selectedRowsData,
      selectedRowKeys: e.selectedRowKeys,
    });
  };

  onShowFilterRowChanged = (e: any): void => {
    this.setState({
      showFilterRow: e.value,
    });
    this.clearFilter();
  };

  onShowHeaderFilterChanged = (e: any): void => {
    this.setState({
      showHeaderFilter: e.value,
    });
    this.clearFilter();
  };

  // onContentReady = (): void => {
  //   this.setState({
  //     loadPanelEnabled: false,
  //   });
  // };

  // TODO: to make the data type generic
  constructor(props: ICustomDataGridProps) {
    super(props);
    this.columns = props.columns ?? [];
    this.dataSource = props?.data ?? [];
    this.applyFilterTypes = [
      {
        key: 'auto',
        name: 'Immediately',
      },
      {
        key: 'onClick',
        name: 'On Button Click',
      },
    ];
    // TODO: To make this as optional (prop)
    // this.contextMenuItems = [{ text: 'Download' }, { text: 'Preview' }];

    this.state = {
      autoNavigateToFocusedRow: true,
      // loadPanelEnabled: true,
      checkboxesMode: 'always',
      currentFilter: this.applyFilterTypes[0].key,
      focusedRowKey: undefined,
      selectedDocuments: [],
      selectedRowKeys: [],
      showFilterRow: true,
      showHeaderFilter: true,
    };

    this.dataGridRef = React.createRef();
  }

  calculateFilterExpression(
    filterValue: any,
    selectedFilterOperation: null | string,
    target: string,
  ) {
    if (target === 'headerFilter') {
      return [`revision_date`, `contains`, filterValue.text];
    }

    if (filterValue.target === 'filterRow') {
      return [`revision_date`, `contains`, filterValue];
    }

    return [filterValue, selectedFilterOperation, target];
  }

  componentDidMount(): void {
    this.dataGridRef.current?.instance.pageIndex(0);
  }

  render() {
    if (!Array.isArray(this.dataSource) || this.dataSource.length === 0) {
      return null;
    }

    return (
      <DevExtremeDataGrid
        allowColumnReordering
        allowColumnResizing
        autoNavigateToFocusedRow={this.state.autoNavigateToFocusedRow}
        columnAutoWidth
        columnMinWidth={50}
        dataSource={this.dataSource}
        // onContentReady={this.onContentReady}
        focusedRowEnabled
        focusedRowKey={this.state.focusedRowKey}
        hoverStateEnabled
        id="gridContainer"
        keyExpr="uuid"
        onCellClick={this.props?.onDocumentNumberClick}
        onContextMenuPreparing={this.addMenuItems}
        onFocusedRowChanged={this.onFocusedRowChanged}
        onFocusedRowChanging={this.onFocusedRowChanging}
        onSelectionChanged={this.onSelectionChanged}
        ref={this.dataGridRef}
        rowAlternationEnabled
        selectedRowKeys={this.state.selectedRowKeys}
        showBorders
      >
        <ColumnFixing enabled />

        <FilterRow
          applyFilter={this.state.currentFilter}
          visible={this.state.showFilterRow}
        />

        <HeaderFilter visible={this.state.showHeaderFilter} />

        <LoadPanel enabled />

        <Scrolling mode="infinite" />

        <Selection
          mode="multiple"
          selectAllMode={`allPages`}
          showCheckBoxesMode={`always`}
        />

        <Sorting mode="multiple" />

        {this.columns.map((column) => {
          return (
            <Column
              calculateFilterExpression={
                column.name === 'revision_date'
                  ? this.calculateFilterExpression
                  : undefined
              }
              caption={column.title}
              cellRender={column?.cellRender}
              customizeText={column?.customizeText}
              dataField={column.name}
              dataType={column?.dataType}
              key={column.name}
              width={column?.width}
              {...column}
            >
              <HeaderFilter
                allowSearch={!!column?.allowSearch}
                dataSource={column?.headerFilter?.dataSource}
              />
            </Column>
          );
        })}

        {/* <ContextMenu
						dataSource={this.contextMenuItems}
						width={200}
						target="#image"
						hoverStateEnabled
						focusStateEnabled
						onItemClick={this.onContextMenuItemClick}
					/> */}

        {this.props.children}
      </DevExtremeDataGrid>
    );
  }
}
