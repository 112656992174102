import { useQuery } from '@tanstack/react-query';

import { fetchJsonApi } from '@/helpers/api';

export default function useFileDownloadLink({
  enabled = true,
  uuid,
}: {
  enabled?: boolean;
  uuid: string;
}) {
  return useQuery({
    enabled: !!uuid && enabled,
    queryFn: async () => {
      const response = await fetchJsonApi(
        `/file/download/${uuid}?file_type=pdf`,
      ).then((res) => res.json());

      if (!response.data) {
        throw new Error('Cannot get file download link');
      }

      return response.data;
    },
    queryKey: ['fileDownloadLink', uuid],
  });
}
