import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

import { fetchJsonApi } from '@/helpers/api';
import { msalInstance } from '@/helpers/msal';

export const tagsAtom = atom<string[]>([]);

export const searchLogQueryAtom = atomWithQuery(() => ({
  queryFn: async () => {
    const activeAccount = msalInstance.getActiveAccount();

    const res = await fetchJsonApi(
      `/user/search-logs?user_email=${activeAccount?.username}`,
    );
    const data = await res.json();

    return data;
  },
  queryKey: ['searchLogs'],
}));

export const removeSearchLogAtom = atomWithMutation((get) => ({
  mutationFn: async (id: string) => {
    await fetchJsonApi(`/user/search-logs/${id}`, {
      method: 'DELETE',
    });
  },
  onSuccess: () => {
    get(searchLogQueryAtom).refetch();
  },
}));
