import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';

import { PageUrl } from '../constants';
import { msalConfig } from '../constants/msalConfig';

export const ACCOUNT_STORAGE_KEY = 'UserAccount';

export const msalInstance = new PublicClientApplication(msalConfig);

const getAccount = (): AccountInfo | undefined => {
  if (!window || !window.localStorage) {
    return undefined;
  }

  try {
    const account = localStorage.getItem(ACCOUNT_STORAGE_KEY);
    if (account) {
      return JSON.parse(account);
    }
  } catch (e) {
    // Case: user edit localStorage that is not a json format manually
    removeAccount();
    return undefined;
  }
};

const setAccount = (account: AccountInfo): void =>
  localStorage.setItem(ACCOUNT_STORAGE_KEY, JSON.stringify(account));

const removeAccount = (): void => localStorage.removeItem(ACCOUNT_STORAGE_KEY);

const getAccessTokenAsync = async () => {
  const userAccount = msalInstance.getActiveAccount();

  if (!userAccount) {
    window.location.href = PageUrl.LOGIN;
  } else {
    const tokenRequest = {
      scopes: [process.env.REACT_APP_AZURE_REDIRECT_SCOPES ?? ''],
    };

    const token = await msalInstance
      .acquireTokenSilent({ ...tokenRequest, account: userAccount })
      .then((res) => res.accessToken)
      .catch(async (error) => {
        console.error(error);

        // Silent token acquisition failed, fallback to interactive method
        const res = await msalInstance.acquireTokenPopup({
          ...tokenRequest,
          redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
        });
        return res.accessToken;
      });

    return token;
  }
};

export const msal = {
  getAccessTokenAsync,
  getAccount,
  removeAccount,
  setAccount,
};
