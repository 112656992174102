import { atom } from 'jotai';
import { atomWithMutation } from 'jotai-tanstack-query';

export const helpFormOpenAtom = atom(false);

type HelpRequestPayload = {
  comment: string;
  issue: 'Document' | 'Other';
  keyword_used: string;
  mail: string;
  screenshots: File[];
  targeted_document: string;
};

export const helpFormMutationAtom = atomWithMutation(() => ({
  mutationFn: (payload: HelpRequestPayload) => {
    const formData = new FormData();
    formData.append('mail', payload.mail);
    formData.append('issue', payload.issue);
    formData.append('keyword_used', payload.keyword_used ?? '');
    formData.append('targeted_document', payload.targeted_document ?? '');
    formData.append('comment', payload.comment);

    if (payload.screenshots[0])
      formData.append('screenshots', payload.screenshots[0]);

    return fetch(`${process.env.REACT_APP_API_URL}/user/support`, {
      body: formData,
      method: 'POST',
    });
  },
  mutationKey: ['helpFormSubmit'],
}));
