import { useAtomValue } from 'jotai';
import { forwardRef, HTMLAttributes } from 'react';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { selectedProjectsAtom } from './ProjectList';

export default forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { className, ...other } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedProjects = useAtomValue(selectedProjectsAtom);

    return (
      <div className="text-white">
        <ul
          {...other}
          className={twMerge(
            className,
            'py-0 [&_.MuiAutocomplete-option]:!bg-transparent [&_.MuiCheckbox-root]:mr-3 [&_.MuiCheckbox-root]:!p-0',
          )}
          ref={ref}
        />

        <div className="flex items-center justify-end px-[10px] py-3">
          <button
            className="rounded-lg bg-[#E4E4E4] px-4 py-[10px] text-black"
            onMouseDown={() => {
              searchParams.delete('project_codes');

              selectedProjects.forEach((project) => {
                searchParams.append('project_codes', project.value);
              });

              setSearchParams(searchParams);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  },
);
