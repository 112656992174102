import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';

import { colors } from '../../constants';
import { formatDateString } from '../../helpers/utils';
import { FileInfoV2 } from '../../services/SearchService';
import DocumentPopper from '../document/DocumentPopper';
import { SearchTerm } from '../pdf/pdf';
import { HoverableTitle } from './HoverableTitle';

interface ThumbDetailsProps {
  file: FileInfoV2;
  onViewPdf?: (
    file: FileInfoV2,
    searchTerms: SearchTerm[],
    highlight?: PdfHighlight,
  ) => void;
  tags: string[];
}

export const ThumbDetails = ({
  file,
  onViewPdf,
  tags,
}: ThumbDetailsProps): ReactElement => {
  const { i18n, t } = useTranslation();

  const coloredTerms: SearchTerm[] = useMemo(
    () =>
      tags.map((value, index) => {
        return { color: colors[index].rgba, term: value };
      }),
    [tags],
  );

  return (
    <div className="thumb-details flex-grow">
      <div className="thumb-details__header">
        <HoverableTitle
          title={file.title || t('document_preview_text_untitled')}
        />

        <DocumentPopper
          file={file}
          viewFile={() => {
            onViewPdf?.(file, coloredTerms);
          }}
        />
      </div>

      <div className="thumb-details__content">
        <div>
          <strong>{t('document_preview_text_document_number')}</strong>

          <span title={file.document_number}>
            {file.document_number || '-'}
          </span>
        </div>

        <div className="thumb-details__revision">
          <div>
            <strong>{t('document_preview_text_revision')}</strong>
            <span>{file.revision}</span>
          </div>

          <div>
            <strong>{t('document_preview_text_revision_date')}</strong>
            <span>{formatDateString(file?.revision_date, i18n.language)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
