import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

import { fetchJsonApi } from '@/helpers/api';

export const documentIdAtom = atom('');

export type NativeFile = {
  mime_type: string;
  title: string;
  url: string;
};

export const documentRequestQueryAtom = atomWithQuery((get) => ({
  enabled: get(documentIdAtom) !== '',
  queryFn: async () => {
    const documentRequestResponse = await fetchJsonApi(
      `/file/download/${get(documentIdAtom)}/document-requests/new`,
    );
    const documentRequest = await documentRequestResponse.json();

    let documentRequestList;

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    while (!documentRequestList || documentRequestList.status !== 'completed') {
      const documentRequestListResponse = await fetchJsonApi(
        `/file/download/document-requests/${documentRequest.uuid}/result`,
      );
      documentRequestList = await documentRequestListResponse.json();

      await sleep(1000);
    }

    return documentRequestList.result as NativeFile[];
  },
  queryKey: ['documentRequest', get(documentIdAtom)],
}));
